import React from "react"
import classnames from "classnames"
import "./Input.scss"

export default function Input(props) {
  const type = props.type || "text"
  const { className, ...rest } = props

  const classNames = classnames({
    input: true,
    range: type === "range",
    text: type === "text",
    [className]: className,
  })

  return <input {...rest} className={classNames} />
}

import React from "react"
import classnames from "classnames"
import "./Message.scss"

function Message(props) {
  const { as = "p", variant, children, show = true, ...rest } = props
  const Component = as
  const classNames = classnames({
    message: true,
    default: !variant,
    error: variant === "error",
    // TODO add other variants
  })

  if (!show) {
    return null
  }
  return (
    <>
      <Component {...rest} className={classNames}>
        {children}
      </Component>
    </>
  )
}

export default Message

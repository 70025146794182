import React from "react"
import { navigate } from "gatsby"
import "./CreateATheme.scss"
import { ChromePicker } from "react-color"
import { Grid, Column } from "./Grid/Grid"
import Input from "./Input/Input"
import Button from "./Button/Button"
import {
  createThemeStyles,
  deleteThemeStyles,
  CZaasThemeContext,
} from "../layouts"
import { getCorrectTextColor } from "../utils/get-contrast-color"
import { useWindowSize } from "react-use"
import Message from "./Message/Message"
const contrast = require("get-contrast")
const color = require("color")

const themeInitialState = {
  name: "",
  fg: "#fff",
  bg: "#3d3a3a",
  primary: "#15e7d2",
  "primary-light": color("#15e7d2")
    .lighten(0.2)
    .hex(),
  "primary-dark": color(color("#15e7d2").darken(0.25)).hex(),
  "text-on-primary": "#000", // should be primary text
  error: "#E10711",
  "error-text": "#fff",
  "border-radius": "9px",
}

// Creates a scale of colors based on the fg
// determines if color is dark
// If dark; puts that color on opposite side of scale because it is the contrast; so it would be the lightest of the shade
// give 7 total percentages of the color
function getShade(fg) {
  const isDarkColor = color(fg).isDark()
  const orderIfIsDark = [0, 1, 2, 3, 4, 5, 6]
  let order

  const shades = {
    lightest: "",
    lighter: "",
    light: "",
    base: "",
    dark: "",
    darker: "",
    darkest: "",
  }

  if (!isDarkColor) {
    order = orderIfIsDark
  } else {
    order = orderIfIsDark.reverse()
  }

  // don't need "l"
  // const [h, s, l] = color(fg).color
  orderIfIsDark.forEach((order, index) => {
    shades[order] = color(fg)
      .lighten(0.166 * (orderIfIsDark.length - index))
      .hsl().color
  })

  return shades
}

export default function CreateATheme(props) {
  const { saveTheme, themeStore } = React.useContext(CZaasThemeContext)
  const [showPreview, setShowPreview] = React.useState(false)
  const [newTheme, setNewTheme] = React.useState(themeInitialState)
  const [themeNameIsGood, setThemeNameIsGood] = React.useState(true)

  const { width } = useWindowSize()

  // initialTheme includes a >= "good" score for contrast
  const [bgFgContrastIsGood, setBgFgContrastIsGood] = React.useState(true)
  const [bgPrimaryContrastIsGood, setBgPrimaryContrastIsGood] = React.useState(
    true
  )
  const [bgErrorContrastIsGood, setErrorBgContrastIsGood] = React.useState(true)

  function validateAll() {
    validateBgFg()
    validateBgPrimary()
    validateBgFg()
  }

  // this is run every render so the score will always be up-to-date
  const bgFgContrastScore = getA11yScore(
    contrast.score(newTheme.fg, newTheme.bg)
  )
  const bgPrimaryContrastScore = getA11yScore(
    contrast.score(newTheme.primary, newTheme.bg)
  )
  const bgErrorContrastScore = getA11yScore(
    contrast.score(newTheme.error, newTheme.bg)
  )

  // actual validation functions
  function validateBgFg() {
    const isGood = bgFgContrastScore.includes("good")
    setBgFgContrastIsGood(isGood)
  }
  function validateBgPrimary() {
    const isGood = bgPrimaryContrastScore.includes("good")
    setBgPrimaryContrastIsGood(isGood)
  }
  function validateErrorBg() {
    const isGood = bgErrorContrastScore.includes("good")
    setErrorBgContrastIsGood(isGood)
  }

  // anytime a {key:value} of the theme changes
  function handleChange(key, value) {
    validateAll()
    setNewTheme({
      ...newTheme,
      [key]: value,
    })
  }
  // except the theme name, border radius
  function handleOtherInput(e) {
    let { name, value } = e.target
    if (!themeNameIsGood) {
      setThemeNameIsGood(e.target.value.trim())
    }
    if (name === "border-radius") {
      value = `${value}px`
    }
    setNewTheme({ ...newTheme, [name]: value })
  }

  // watch for primary color change
  // add color and light/dark color variants
  React.useEffect(() => {
    const { primary } = newTheme
    let textOnPrimaryColor
    const primaryIsDark = color(primary).isDark()
    const shadeNeeded = primaryIsDark ? "light" : "dark"

    const primaryLight = color(primary)
      .lighten(0.2)
      .hex()
    const primaryDark = color(primary)
      .darken(0.2)
      .hex()
    setNewTheme({
      ...newTheme,
      "text-on-primary": getCorrectTextColor(primary),
      "primary-light": primaryLight,
      "primary-dark": primaryDark,
    })
  }, [newTheme.primary])

  // anytime error color changes
  // add text color
  React.useEffect(() => {
    const { error } = newTheme
    setNewTheme({
      ...newTheme,
      "error-text": getCorrectTextColor(error),
    })
  }, [newTheme.error])

  // React.useEffect(() => {
  //   getShade(newTheme.fg)
  // }, [newTheme.fg])

  function handleSave() {
    const themeName = newTheme.name.trim()
    setThemeNameIsGood(themeName)
    if (themeName && bgFgContrastIsGood && bgPrimaryContrastIsGood) {
      saveTheme(newTheme)
      navigate("/themes")
    }
  }
  function handlePreview() {
    if (bgFgContrastIsGood && bgPrimaryContrastIsGood) {
      setShowPreview(true)
    }
  }

  React.useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search)
      const themeId = params.get("theme-id")

      if (themeId) {
        const editingTheme = window.localStorage.getItem(themeId)
        const parsedTheme = JSON.parse(editingTheme)
        setNewTheme(parsedTheme)
      }
    } catch (e) {}
  }, [])

  return (
    <>
      <div className="themeCreator">
        <div>
          <h2>First, pick a foreground and background</h2>
          <Grid gutter="16">
            <Column base="1-2">
              Foreground
              <ColorViewer
                themeKey="fg"
                theme={newTheme}
                handleChange={handleChange}
              />
            </Column>
            <Column base="1-2">
              Background
              <ColorViewer
                themeKey="bg"
                theme={newTheme}
                handleChange={handleChange}
              />
            </Column>
            <Column>
              <Message variant="error" show={!bgFgContrastIsGood}>
                Fix Foreground to background contrast
              </Message>
            </Column>
          </Grid>

          <h2>Next, pick your primary color</h2>
          <p>
            A light and dark version will be programmatically generated between
            the two +/- 10%
          </p>

          <Grid gutter="16">
            <Column sm="1" md="1-3">
              Primary
              <ColorViewer
                themeKey="primary"
                theme={newTheme}
                handleChange={handleChange}
              ></ColorViewer>
            </Column>
            <Column sm="1" md="1-3">
              Primary Dark
              <ColorViewer
                themeKey="primary-dark"
                theme={newTheme}
                handleChange={handleChange}
                theme={newTheme}
                disabled
              />
            </Column>
            <Column sm="1" md="1-3">
              Primary Light
              <ColorViewer
                themeKey="primary-light"
                theme={newTheme}
                handleChange={handleChange}
                disabled
              />
            </Column>
            <Column>
              <Message
                variant="error"
                show={!bgPrimaryContrastIsGood}
                style={{ marginTop: -12 }}
              >
                Fix primary to background contrast!
              </Message>
            </Column>
          </Grid>

          <Grid gutter="16">
            <Column>
              Error Color
              <ColorViewer
                themeKey="error"
                theme={newTheme}
                handleChange={handleChange}
              ></ColorViewer>
            </Column>
            <Column />
          </Grid>

          <Grid gutter="16">
            <Column>Border radius</Column>
            <Column className="center" sm="1-8">
              {newTheme["border-radius"]}
            </Column>
            <Column sm="7-8">
              <Input
                type="range"
                min="0"
                max="30"
                value={Math.abs(
                  newTheme["border-radius"].replace(/[a-z]/gi, "")
                )}
                name="border-radius"
                onChange={handleOtherInput}
              />{" "}
            </Column>
          </Grid>

          <h2>Finally, name your theme</h2>
          <Grid>
            <Column>
              <label htmlFor="themeName">Name</label>
              <Input
                value={newTheme.name}
                onChange={handleOtherInput}
                id="themeName"
                name="name"
              />
              <Message variant="error" show={!themeNameIsGood}>
                Please add a theme name
              </Message>
            </Column>
          </Grid>
          <Grid>
            <Column>
              <Button inline onClick={handleSave}>
                Save
              </Button>
              {width <= 768 && (
                <Button inline onClick={handlePreview}>
                  Preview
                </Button>
              )}
            </Column>
          </Grid>
          {showPreview && width <= 768 && (
            <div className="modal">
              <PreviewTheme
                theme={{ ...newTheme, id: "preview" }}
                handleClose={e => setShowPreview(false)}
              />
            </div>
          )}
        </div>
        {width > 768 && (
          <PreviewTheme
            theme={{ ...newTheme, id: "preview" }}
            handleClose={e => setShowPreview(false)}
          />
        )}
      </div>
    </>
  )
}

function ColorViewer(props) {
  const { themeKey, theme, handleChange, disabled, children } = props
  return (
    <div
      className={`colorViewer ${disabled ? "disabled" : ""}`}
      style={{
        background: theme[themeKey],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
      <div className={`colorViewer__popup ${disabled ? "disabled" : ""}`}>
        <ChromePicker
          color={theme[themeKey]}
          onChange={color => handleChange(themeKey, color.hex)}
          onChangeComplete={color => handleChange(themeKey, color.hex)}
        />
      </div>
    </div>
  )
}

function getA11yScore(score) {
  switch (score) {
    case "AAA":
      return "really good"
    case "AA":
      return "good"
    case "F":
      return "bad"
    default:
      return "unknown"
  }
}

function PreviewTheme(props) {
  const { theme, handleClose } = props
  const { width } = useWindowSize()
  function handleEscapeListener(e) {
    if (e.key === "Escape") {
      handleClose()
    }
  }
  React.useEffect(() => {
    createThemeStyles({ theme, cssScope: ".preview" })
    window.addEventListener("keydown", handleEscapeListener)
    return () => {
      deleteThemeStyles({ theme })
      window.removeEventListener("keydown", handleEscapeListener)
    }
  }, [theme])
  return (
    <div className={`preview`}>
      <div>
        {width < 768 && <div onClick={handleClose}>X</div>}
        <h1>
          {theme.name.trim() === ""
            ? "Add a theme name"
            : `Preview of "${theme.name}"`}
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          eu metus lobortis, cursus diam non, euismod nulla. Donec at ex diam.
          Nulla facilisi. Donec enim lorem, pulvinar quis commodo ut, eleifend
          sed ante.
        </p>
        <label>
          This is a test
          <Input placeholder="This does nothing" />
        </label>
        <div>
          <Button inline importance="primary">
            inline importance=primary
          </Button>
          <Button inline>inline default</Button>
          <Button inline incognito>
            inline default incognito
          </Button>
        </div>

        <Message variant="error">
          This is an example error message `p` tag
        </Message>

        <Input min="0" max="100" type="range" />

        <p>
          Cras varius lorem sed nibh mollis sollicitudin. Mauris egestas erat
          viverra urna feugiat, in mattis metus fermentum. Maecenas porttitor,
          nulla quis volutpat pellentesque, enim augue luctus ante, vel
          dignissim mauris urna at est. Proin mattis laoreet ex, eget tempor
          tortor aliquam vel. Duis convallis, turpis at porttitor varius, enim
          sapien iaculis nisl, ac lobortis ex erat quis lorem. Vestibulum nec
          consectetur diam, quis interdum nisi. Vivamus ut viverra dolor, et
          vehicula arcu. In in ligula nunc. Aliquam eu suscipit urna. Ut vel
          neque dui.
        </p>
      </div>
    </div>
  )
}
